#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: #15b5dd;
  position: fixed;
  z-index: 1101;
  top: 0px;
  left: 0;
  width: 100%;
  height: 0.15rem;
}
/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 6.25rem;
  height: 100%;
  box-shadow: 0 0 0.625rem #15b5dd, 0 0 0.3125rem #15b5dd;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0, -0.25rem);
  -ms-transform: rotate(3deg) translate(0, -0.25rem);
  transform: rotate(3deg) translate(0, -0.25rem);
}
/* Remove these to get rid of the spinner */
/* #nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 0.9375rem;
  right: 0.9375rem;
} */
/* #nprogress .spinner-icon {
  width: 1.125rem;
  height: 1.125rem;
  box-sizing: border-box;
  border: solid 0.125rem transparent;
  border-top-color: #15b5dd;
  border-left-color: #15b5dd;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
} */
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
